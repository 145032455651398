/* you can find the theme files in _sass folder */
@import "jekyll-theme-chirpy";

$theme-primary: #F05F40;
$theme-dark: #222;

/* append your custom style below */

[type="search"]::-webkit-search-cancel-button {
  // -webkit-appearance: none;
  // appearance: none;
  height: 10px;
  width: 10px;
}

#mainNavbar {
  background-image: url(/assets/img/nav_home_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  #search-input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: solid 2px #333333;
    width: 300px;
    font-size: 1.2rem;
    font-weight: bold;

    &:focus {
      box-shadow: none;
      color: #ffffff;
      border-bottom-color: #ffffff;
    }
  }
}

#projects {
  padding-top: 100px;

  .widget {
    max-height: 350px;
    & > a {
      display: block;
      text-align: center;
      width: 100%;
      height: 200px;
      border: 1px solid #dedede;
      border-radius: 4px;
      box-shadow: 2px 2px 2px #dedede;
      overflow: hidden;
      margin-bottom: 1rem;
  
      &:hover {
        border-color: $theme-primary;
        box-shadow: 2px 2px 5px $theme-primary;
      }
  
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

#about {
  padding-top: 60px;

  .bg {
    padding: 3rem 0;
    text-align: center;
    background-image: url(https://static.bndy.net/images/bg.jpg);
    background-size: cover;
  }

  h1, a, p {
    color: #ffffff;
  }

  .animated {
    display: inline-block;
    animation-delay: 2s;
  }
  [toggle-css=fadeInUp] {
    display: none;
  }
  img.avatar {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  a.item {
    display: inline-block;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    min-width: 100px;
    border-radius: 5px;
    &:hover {
      text-decoration: none;
    }
    img {
      height: 30px;
    }
    i,
    h4 {
      margin-top: 0.5rem;
      color: #ffffff;
    }
  }
}

#contact {
  background-color: $theme-primary;
  padding: 3rem 0;
  text-align: center;

  h1, a, p {
    color: #ffffff;
  }
}

#blog {
  padding-top: 100px;
}

#core-wrapper {
  margin-top: 80px;
}

footer {
  background-color: #313131;
  color: #dedede;
  font-size: 1rem;

  a {
    color: #dedede;
  }
}