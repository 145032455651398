/*
 * The SCSS variables
 */

/* tabs of sidebar */

$tab-count: 5 !default;   /* backward compatible (version <= 4.0.2) */
$tab-height: 3rem !default;
$tab-cursor-height: 1.6rem !default;
$cursor-width: 2px !default;    /* the cursor width of the selected tab */

/* other framework sizes */

$topbar-height: 3rem !default;
$search-max-width: 210px !default;
$footer-height: 60px !default;
$footer-height-mobile: 60px !default;   /* screen width: <= 576px */

$main-content-max-width: 1250px !default;
$bottom-min-height: 35rem !default;

/* syntax highlight */

$code-font-size: 0.85rem !default;
